import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1>Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
